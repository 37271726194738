<template>
  <ul class="nav justify-content-start">
    <li class="nav-item" v-for="item in brands" :key="item.brand_id">
      <router-link to=""
                   class="nav-link ps-0 pe-5 text-black text-navigate-link"
                   @click="setBrand(item)">
        <div
          :class="item.brand===brand.brand ?
           'border-bottom border-primary border-3':'border-bottom border-3 border-transparent'">
          {{ item.name }}
        </div>
      </router-link>
    </li>
  </ul>
</template>

<script setup>
import { useStore } from 'vuex'
import { computed, defineProps } from 'vue'

const store = useStore()
const brand = computed(() => store.state.brand)
const setBrand = (brandObject) => store.commit('setBrand', brandObject)

defineProps({
  brands: Array,
})
</script>

<style scoped>
.border-transparent {
  border-color: transparent !important;
}

.text-navigate-link {
  font-size: 18px;
}
</style>
