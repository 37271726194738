import { $themeConfig } from '@/../themeConfig'

export default {
  // Endpoints
  loginEndpoint: `${$themeConfig.app.backUrlAuth}/login`,
  refreshEndpoint: `${$themeConfig.app.backUrlAuth}/refresh`,
  logoutEndpoint: `${$themeConfig.app.backUrlAuth}/logout`,

  // This will be prefixed in authorization header with token
  // e.g. Authorization: Bearer <token>
  tokenType: 'Bearer',

  // Value of this property will be used as key to store JWT token in storage
  storageTokenKeyName: 'adminstories:accessToken',
  storageRefreshTokenKeyName: 'adminstories:refreshToken',
  storageUserData: 'adminstories:userData',
}
