<template>
  <div class="modal fade" id="exampleModal" tabindex="-1" aria-labelledby=""
       aria-hidden="true" ref="modalEle">
    <div class="modal-dialog modal-dialog-centered" :id="id">
      <div class="modal-content">
        <div class="modal-header d-none">
          <button type="button" class="btn-close"
                  data-bs-dismiss="modal"
                  aria-label="Close">
          </button>
        </div>
        <div class="modal-body
                    d-flex
                    align-items-center
                    flex-column
                    text-center">
          <h5 class="modal-title mb-2" id="exampleModalLabel">
            {{ title }}
          </h5>
          <slot name="body"/>
          <slot name="footer"></slot>
        </div>
        <div class="modal-footer d-none">
          <slot name="footer"></slot>
          <button type="button" class="btn btn-secondary"
                  data-bs-dismiss="modal">
            Close
          </button>
        </div>
      </div>
    </div>
  </div>
</template>

<script setup>
import {
  onMounted,
  ref,
  defineProps,
  defineExpose,
} from 'vue'
import { Modal } from 'bootstrap'

defineProps({
  id: {
    type: String,
    default: 'modalDeleteStory',
  },
  title: {
    type: String,
    default: '<<Title goes here>>',
  },
})
const modalEle = ref(null)
const thisModalObj = ref(null)

onMounted(() => {
  thisModalObj.value = new Modal(modalEle.value)
})

// eslint-disable-next-line
const _show = () => {
  thisModalObj.value.show()
}
// eslint-disable-next-line
const _hide = () => {
  thisModalObj.value.hide()
}
defineExpose({
  show: _show,
  hide: _hide,
})
</script>
<style scoped>
#modalDeleteStory {
  width: 342px;
}
</style>
