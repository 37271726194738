import { computed, ref } from 'vue'
import store from '@/store'

const endDateBeforeStartDate = (start_init_date, start_time, end_init_date, end_time) => {
  if (start_init_date && start_time && end_init_date && end_time) {
    const date1 = new Date(start_init_date)
    const date2 = new Date(end_init_date)
    const [h1, m1] = start_time.split(':')
    const [h2, m2] = end_time.split(':')

    date1.setHours(h1, m1)
    date2.setHours(h2, m2)

    return date1 > date2
  }
  return false

  // new Date(year, month[, day[, hour[, minute[, second[, millisecond]]]]]);
}

export function useStaticData() {
  const tableColumnsActiveStories = [
    {
      key: 'col0',
      label: '',
      class: '',
    },
    {
      key: 'col1',
      label: 'Превью',
      class: 'ps-3',
    },
    {
      key: 'col2',
      label: '№ в МП',
      class: '',
    },
    {
      key: 'col3',
      label: 'дата / время',
      class: '',
    },
    {
      key: 'col31',
      label: 'Города',
      class: '',
      style: 'min-width: 170px',
    },
    {
      key: 'col4',
      label: 'Оценки',
      class: 'text-center',
    },
    {
      key: 'col5',
      label: 'Заголовок',
      class: '',
    },
    {
      key: 'col6',
      label: 'Ссылка',
      class: '',
    },
    {
      key: 'col7',
      label: '',
      class: '',
    },
    {
      key: 'col8',
      label: '',
      class: '',
    },
    {
      key: 'col9',
      label: '',
      class: '',
    },
  ]
  const tableColumnsBasketStories = [
    {
      key: 'col1',
      label: 'Превью',
      class: 'ps-3',
    },
    {
      key: 'col2',
      label: '№ в МП',
      class: '',
    },
    {
      key: 'col3',
      label: 'Дата публикации',
      class: '',
    },
    {
      key: 'col5',
      label: 'Название',
      class: '',
    },
    {
      key: 'col7',
      label: '',
      class: '',
    },
  ]
  const types = {
    text: ['title', 'image', 'description'],
    button: ['image', 'button-title', 'button-hex', 'button-link'],
    picture: ['image'],
  }
  const typesStories = (typeStory, nameField) => (!types[typeStory]
    ? false : types[typeStory].includes(nameField))

  return {
    tableColumnsActiveStories,
    tableColumnsBasketStories,
    typesStories,
  }
}

export function useBrand(params) {
  const { defBrand } = store.state
  const brandLS = localStorage.admin_brand

  const brand = computed(() => {
    if (params.brand) return { name: params.brand }
    return brandLS ? { name: brandLS } : defBrand
  })

  return {
    brand,
  }
}

export function useImg() {
  const postImage = (content, uuid) => store.dispatch('postImg', {
    content,
    uuid,
  })
  return {
    postImage,
  }
}

export function useStories() {
  const fetchStories = (...args) => store.dispatch('fetchStories', ...args)
  const postStory = (formData) => store.dispatch('postStory', formData)
  const postQueue = (formData) => store.dispatch('postQueue', formData)
  const patchStory = (formData) => store.dispatch('patchStory', formData)
  const fetchStory = (id) => store.dispatch('fetchStory', id)
  const deleteStory = (...args) => store.dispatch('deleteStory', ...args)
  const activeStory = (...args) => store.dispatch('activeStory', ...args)
  const refreshStory = (id) => store.dispatch('refreshStory', id)
  const recoveryStory = (...args) => store.dispatch('recoveryStory', ...args)
  const baseStories = ref({
    fixed: false,
    sort_id: 0,
    start_init_date: null,
    start_time: null,
    end_init_date: null,
    end_time: null,
    preview:
      {
        title: '',
        image: '',
        description: '',
        content: '',
      },
    stories: [],
  })
  const baseStoryConsists = ref(
    {
      title: '',
      image: '',
      description: '',
      button: {},
      type: 'text',
      object_fit: 'cover',
    },
  )

  return {
    fetchStories,
    postStory,
    baseStories,
    baseStoryConsists,
    fetchStory,
    deleteStory,
    recoveryStory,
    patchStory,
    activeStory,
    postQueue,
    refreshStory,
  }
}

export function useValidateForm() {
  const countFixedStories = computed(() => store.getters.getCountFixedStories)
  const errors = ref(Object)
  errors.value = {
    title: {
      value: '',
      valid: true,
      validate(input) {
        this.value = ''
        // if (!input) {
        //   this.value = 'Заполните "Заголовок"'
        //   return false
        // }
        return true
      },
      pageError: 0,
    },
    description: {
      value: '',
      valid: true,
      validate(input) {
        this.value = ''
        // if (!input) {
        //   this.value = 'Заполните "Описание"'
        //   return false
        // }
        return true
      },
      pageError: 0,
    },
    image: {
      value: '',
      valid: true,
      validate(input) {
        this.value = ''
        if (!input) {
          this.value = 'Загрузите файл'
          return false
        }
        return true
      },
      pageError: 0,
    },
    stories:
      {
        value: {},
        valid: true,
        validate(input, checkImage = true) {
          this.value = {}
          if (input === undefined) {
            return true
          }
          const ret = ref(true)
          input.forEach((el, ind) => {
            this.value[ind] = {}
            if ('type' in el) {
              if (el.type === 'text') {
                // if (!el.title) {
                //   this.value[ind].title = 'Заполните "Заголовок"'
                //   ret.value = false
                // }
                // if (!el.description) {
                //   this.value[ind].description = 'Заполните "Описание"'
                //   ret.value = false
                // }
                if (checkImage && !el.image && !el.imageForLoading) {
                  this.value[ind].image = 'Загрузите файл'
                  ret.value = false
                }
              } else if (el.type === 'button') {
                if (checkImage && !el.image && !el.imageForLoading) {
                  this.value[ind].image = 'Загрузите файл'
                  ret.value = false
                }
                if (!el.button.title) {
                  this.value[ind]['button-title'] = 'Заполните "Название"'
                  ret.value = false
                }
                if (!el.button.hex) {
                  this.value[ind]['button-hex'] = 'Выберите цвет кнопки'
                  ret.value = false
                }
                if (!el.button.link) {
                  this.value[ind]['button-link'] = 'Заполните "DeepLink"'
                  ret.value = false
                }
              } else if (checkImage && !el.image && !el.imageForLoading) {
                this.value[ind].image = 'Загрузите файл'
                ret.value = false
              }
            }
          })
          return ret.value
        },
        pageError: 1,
      },
  }

  const errorsDate = ref(Object)
  errorsDate.value = {
    start_init_date: {
      value: '',
      valid: true,
      pageError: 0,
    },
    start_time: {
      value: '',
      valid: true,
      pageError: 0,
    },
    end_init_date: {
      value: '',
      valid: true,
      pageError: 0,
    },
    end_time: {
      value: '',
      valid: true,
      pageError: 0,
    },
    date: {
      value: '',
      valid: true,
      pageError: 0,
    },
  }

  const validateAll = (formData) => {
    Object.entries(errors.value)
      .forEach(([key, value]) => {
        // eslint-disable-next-line no-param-reassign
        value.valid = true
        // eslint-disable-next-line no-param-reassign
        value.valid = value.validate(formData.value.preview[key])
      })
    errors.value.stories.valid = true
    errors.value.stories.valid = errors.value.stories.validate(formData.value.stories)
    // errors.value.fixedStory.valid = true
    // errors.value.fixedStory.valid = errors.value.fixedStory.validate(formData.value.fixed)

    return Object.entries(errors.value)
      .every(([key, value]) => value.valid) && Object.entries(errorsDate.value)
      .every(([key, value]) => value.valid)
  }

  const validateAllWithOutImage = (formData) => {
    Object.entries(errors.value)
      .forEach(([key, value]) => {
        if (key !== 'image') {
        // eslint-disable-next-line no-param-reassign
          value.valid = true
          // eslint-disable-next-line no-param-reassign
          value.valid = value.validate(formData.preview[key])
        }
      })
    errors.value.stories.valid = true
    errors.value.stories.valid = errors.value.stories.validate(formData.stories, false)

    return Object.entries(errors.value)
      .every(([key, value]) => value.valid) && Object.entries(errorsDate.value)
      .every(([key, value]) => value.valid)
  }

  const handlerChange = ({ target }) => {
    if (!errors.value[target.name].valid) {
      errors.value[target.name].valid = true
      errors.value[target.name].value = ''
    }
  }
  const validateStories = (stories) => {
    if (!errors.value.stories.valid) {
      errors.value.stories.valid = true
      errors.value.stories.valid = errors.value.stories.validate(stories)
    }
  }

  const checkTime = (time) => {
    const regexPattern = /^([01]?[0-9]|2[0-3]):[0-5][0-9]$/
    const check = new RegExp(regexPattern)
    return check.test(time)
  }

  const checkDates = (story) => {
    const {
      start_init_date, start_time, end_init_date, end_time,
    } = story

    Object.entries(errorsDate.value)
      .forEach(([key, value]) => {
        // eslint-disable-next-line no-param-reassign
        value.valid = true
        // eslint-disable-next-line no-param-reassign
        value.value = ''
      })
    if (!!start_init_date && !start_time) {
      errorsDate.value.start_time.value = 'Заполните время старта'
      errorsDate.value.start_time.valid = false
    }
    if (!!end_init_date && !end_time) {
      errorsDate.value.end_time.value = 'Заполните время окончания'
      errorsDate.value.end_time.valid = false
    }
    if (!start_init_date && !!start_time) {
      errorsDate.value.start_init_date.value = 'Заполните дату старта'
      errorsDate.value.start_init_date.valid = false
    }
    if (!end_init_date && !!end_time) {
      errorsDate.value.end_init_date.value = 'Заполните дату окончания'
      errorsDate.value.end_init_date.valid = false
    }
    if (!!end_time && !checkTime(end_time)) {
      errorsDate.value.end_time.value = 'Неверный формат времени'
      errorsDate.value.end_time.valid = false
    }

    if (!!start_time && !checkTime(start_time)) {
      errorsDate.value.start_time.value = 'Неверный формат времени'
      errorsDate.value.start_time.valid = false
    }
    if (Object.entries(errorsDate.value).every(([key, value]) => value.valid)
      && endDateBeforeStartDate(start_init_date, start_time, end_init_date, end_time)) {
      errorsDate.value.date.value = 'Дата старта должна быть раньше даты окончания'
      errorsDate.value.start_init_date.value = ''
      errorsDate.value.start_time.value = ''
      errorsDate.value.end_time.value = ''
      errorsDate.value.date.valid = false
      errorsDate.value.start_init_date.valid = false
      errorsDate.value.end_init_date.valid = false
      errorsDate.value.start_time.valid = false
      errorsDate.value.end_time.valid = false
    }

    return Object.entries(errorsDate.value)
      .every(([key, value]) => value.valid)
  }

  const pageError = computed(() => {
    const validDate = Object.entries(errorsDate.value).every(([key, value]) => value.valid)
    if (!validDate) return 0
    const find = Object.entries(errors.value)
      .find(([key, value]) => !value.valid)

    return find ? find[1].pageError : -1
  })

  return {
    errors,
    validateAll,
    pageError,
    handlerChange,
    validateStories,
    validateAllWithOutImage,
    checkDates,
    errorsDate,
  }
}

export function useBrands() {
  const fetchedBrands = ref(false)
  const fetchBrands = () => store.dispatch('fetchBrands')
  const setBrands = (brands) => store.commit('SET_BRANDS', brands)
  const setBrand = (brand) => store.commit('setBrand', brand)
  const brands = computed(() => store.state.brands)
  return {
    fetchBrands,
    fetchedBrands,
    setBrands,
    setBrand,
    brands,
  }
}

export function useCities() {
  const fetchedCities = ref(false)
  const fetchCities = () => store.dispatch('fetchCities')
  const setCities = (cities) => store.commit('SET_CITIES', cities)
  const cities = computed(() => store.state.cities)
  const getCitiesOptions = computed(() => store.getters.getCitiesOptions)
  return {
    fetchedCities,
    fetchCities,
    setCities,
    cities,
    getCitiesOptions,
  }
}

export function useMenu() {
  const fetchMenuList = (brand_id) => store.dispatch('fetchMenuList', {
    brand_id,
  })
  const addMenuItem = (menuItem) => store.commit('ADD_MENU_LIST', menuItem)
  const fetchMenu = (controller, menu_id) => store.dispatch('fetchMenu', {
    controller,
    menu_id,
  })
  const postMenu = (...args) => store.dispatch('postMenu', ...args)
  const editMenu = (...args) => store.dispatch('patchMenu', ...args)
  const dropMenu = (...args) => store.dispatch('dropMenu', ...args)
  return {
    fetchMenu,
    postMenu,
    editMenu,
    dropMenu,
    fetchMenuList,
    addMenuItem,
  }
}
