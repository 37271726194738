import { createApp } from 'vue'
import Maska from 'maska'
import App from './App'
import router from './router'
import store from './store'
import './assets/scss/variables.scss'
import 'bootstrap/dist/js/bootstrap'

const app = createApp(App)
app.use(store)
app.use(router)
app.use(Maska)
app.mount('#app')
