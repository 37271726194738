// eslint-disable-next-line import/no-cycle
import router from '@/router'
import jwtDefaultConfig from './jwtDefaultConfig'
// eslint-disable-next-line import/no-cycle

export default class JwtService {
  // Will be used by this service for making API calls
  axiosIns = null;

  // jwtConfig <= Will be used by this service
  jwtConfig = { ...jwtDefaultConfig };

  // For Refreshing Token
  isAlreadyFetchingAccessToken = false;

  // For Refreshing Token
  subscribers = [];

  constructor(axiosIns, jwtOverrideConfig) {
    this.axiosIns = axiosIns
    this.jwtConfig = { ...this.jwtConfig, ...jwtOverrideConfig }

    // Request Interceptor
    this.axiosIns.interceptors.request.use(
      (config) => {
        // Get token from localStorage
        const accessToken = this.getToken()

        // If token is present add it to request's Authorization Header
        if (accessToken) {
          // eslint-disable-next-line no-param-reassign
          config.headers.Authorization = `${this.jwtConfig.tokenType} ${accessToken}`
        }
        return config
      },
      (error) => Promise.reject(error),
    )

    // Add request/response interceptor
    this.axiosIns.interceptors.response.use(
      (response) => response,
      (error) => {
        const {
          config,
          response,
        } = error
        const originalRequest = config
        if (response && response.status === 433) {
          if (!this.isAlreadyFetchingAccessToken) {
            this.isAlreadyFetchingAccessToken = true
            this.refreshToken()
              .then((res) => {
                this.isAlreadyFetchingAccessToken = false
                this.setToken(res.data.access_token)
                this.setRefreshToken(res.data.refresh_token)
                this.onAccessTokenFetched(res.data.refresh_token)
              })
              .catch(() => {
              })
          } else {
            this.setToken('')
            this.setRefreshToken('')
            router.go(0)
          }
          const retryOriginalRequest = new Promise((resolve) => {
            this.addSubscriber((accessToken) => {
              originalRequest.headers.Authorization = `${this.jwtConfig.tokenType} ${accessToken}`
              resolve(this.axiosIns(originalRequest))
            })
          })
          return retryOriginalRequest
        }
        if (response && (response.status === 435 || response.status === 432)) {
          this.setToken('')
          this.setRefreshToken('')
          router.go(0)
        }
        return Promise.reject(error)
      },
    )
  }

  onAccessTokenFetched(accessToken) {
    this.subscribers = this.subscribers.filter((callback) => callback(accessToken))
  }

  addSubscriber(callback) {
    this.subscribers.push(callback)
  }

  getToken() {
    return localStorage.getItem(this.jwtConfig.storageTokenKeyName)
  }

  getRefreshToken() {
    return localStorage.getItem(this.jwtConfig.storageRefreshTokenKeyName)
  }

  setToken(value) {
    localStorage.setItem(this.jwtConfig.storageTokenKeyName, value)
  }

  setRefreshToken(value) {
    localStorage.setItem(this.jwtConfig.storageRefreshTokenKeyName, value)
  }

  setUserData(value) {
    localStorage.setItem(this.jwtConfig.storageUserData, value)
  }

  login(...args) {
    return this.axiosIns.post(this.jwtConfig.loginEndpoint, ...args)
  }

  // getUser() {
  //   return this.axiosIns.get(this.jwtConfig.userEndpoint)
  // }

  clearData() {
    localStorage.removeItem(this.jwtConfig.storageUserData)
    localStorage.removeItem(this.jwtConfig.storageTokenKeyName)
    localStorage.removeItem(this.jwtConfig.storageRefreshTokenKeyName)
  }

  refreshToken() {
    this.setToken(this.getRefreshToken())
    return this.axiosIns.post(this.jwtConfig.refreshEndpoint)
  }
}
