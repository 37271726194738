// eslint-disable-next-line import/no-cycle
import useJwt from '@/auth/jwt/useJwt'

// eslint-disable-next-line arrow-body-style
export const isUserLoggedIn = () => {
  return localStorage.getItem(useJwt.jwtConfig.storageUserData)
    && localStorage.getItem(useJwt.jwtConfig.storageTokenKeyName)
}

export const getUserData = () => JSON.parse(localStorage.getItem(useJwt.jwtConfig.storageUserData))

export const getHomeRouteForLoggedInUser = () => ({ name: 'stories' })
