/* eslint-disable */
export function useFormat() {
  // 2022-05-19T12:28:21.503231+00:00
  const parseDate = (strDate) =>
    {
      if(!strDate) return { date: '', time: '' }
      const date = new Date(strDate)
      return {
          date: date.getDate().toString().padStart(2,'0')+'.'+(date.getMonth()+1).toString().padStart(2,'0')+'.'+date.getFullYear(),
          time: date.getHours().toString().padStart(2,'0') + ':' + date.getMinutes().toString().padStart(2,'0'),
          dateAndTime: date.getDate().toString().padStart(2,'0')+'.'+(date.getMonth()+1).toString().padStart(2,'0')  + ' / ' + date.getHours().toString().padStart(2,'0')  + ':' + date.getMinutes().toString().padStart(2,'0') ,
      }
    }
  // 2022-05-19T12:28:21.503231+00:00
  const buildDate = (strDate, strTime) =>
  {
    if(!strDate || !strTime)
      return ""
    const [h,m]  = strTime.split(':')
    const newDate = new Date(strDate)
    newDate.setHours(h,m)
    return newDate.toISOString()
  }

  return {
    parseDate,
    buildDate,
  }
}
