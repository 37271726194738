<template>
  <header class="bg-white">
    <div class="d-grid align-items-center h-100"
         style="grid-template-columns: 344px 1fr;">
      <div class="bg-secondary text-white text-uppercase text-center h-100 line-height-header logo"
      @click="$router.push('/')">
        Admin<b>stories</b>
      </div>
      <div class="d-flex
                  align-items-center
                  justify-content-end
                  content_container"
      >
        <div v-if="userData"
          class="me-3 text-capitalize fw-bolder">
          {{ userData.login }}
        </div>
        <img src="@/assets/img.png" alt="mdo"
             width="52"
             height="52"
             class="rounded-circle img-thumbnail">
        <button class="btn btn-light"
                @click="logout"
        >
          <img src="@/assets/img/Exit.png"
               alt="Img"
               class="me-1"
          />
        </button>

      </div>
    </div>
  </header>
</template>

<script>
import { getUserData } from '@/auth/utils'
import useJwt from '@/auth/jwt/useJwt'
import store from '@/store'
import router from '@/router'

export default {
  name: 'FastHeader',
  computed: {
    userData() {
      return getUserData()
    },
  },
  methods: {
    logout() {
      store.dispatch('logout')
        .then(
          () => {
            useJwt.clearData()
            router.go(0)
          },
        )
    },
  },
}
</script>

<style scoped>
.content_container {
  margin: 0 40px;
}

.line-height-header {
  line-height: 80px;
}

.logo {
  font-size: 30px;
  cursor: pointer;
}

header {
  box-shadow: 0px 4px 30px 0px #0000001A;
}
</style>
