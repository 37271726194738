<template>
  <!--   eslint-disable -->
  <multiselect v-model="value"
               :options="options"
               :mode="mode"
               :close-on-select="false"
               :clear-on-select="false"
               :preserve-search="true"
               placeholder="Город"
               noResultsText="Все города выбраны"
               @input="handleChange"

  >
    <template v-slot:multiplelabel="{ values }">
      <div class="multiselect-multiple-label">
        {{
          values.length > 1 ? `Выбрано: ${values.length}` : values[0].label
        }}
      </div>
    </template>
    <template v-slot:tag="{ option, handleTagRemove, disabled }">
      <div
        class="multiselect-tag is-user"
      >
        {{ option.label }}
        <span
          v-if="!disabled"
          class="multiselect-tag-remove"
          @click="handleTagRemove(option, $event)"
        >
            <span class="multiselect-tag-remove-icon"></span>
          </span>
      </div>
    </template>
  </multiselect>
</template>

<script>
import Multiselect from '@vueform/multiselect'

export default {
  components: {
    Multiselect,
  },
  props: {
    options: Array,
    mode: String,
    startValue: Array,
  },
  emits: ['change'],
  data() {
    return {
      value: [],
    }
  },
  created() {
    this.value = this.startValue
  },
  methods: {
    handleChange(emit) {
      this.$emit('change', emit)
    },
  },
}
</script>

<style lang="scss">
</style>
