export default {
  getCountFixedStories(state) {
    return state.stories.filter((story) => story.fixed).length
  },
  getFixedStories(state) {
    return state.stories.filter((story) => story.fixed)
  },
  getCitiesOptions(state) {
    const ret = []
    state.cities.forEach((e) => (
      ret.push({
        value: e.city_id,
        label: e.name,
      })
    ))
    return ret
  },
}
