<template>
  <router-view/>
</template>

<script setup>
</script>

<style>
@import "assets/css/icons.css";
@import "assets/css/montserrat.css";

html {
  height: 100%;
  background: #333F48;
}

#app {
  font-family: 'Montserrat', serif;
  background: #333F48;
}

.div-shadow {
  box-shadow: inset 0px 4px 30px 0px #0000001A;
}

.text-500 {
  font-weight: 500;
}

.text-disabled {
  color: #ABABAB;
}

/* Для закругления таблицы */
.bordered {
  -moz-border-radius: 6px;
  -webkit-border-radius: 6px;
  border-radius: 6px;
}

.bordered tr:hover {
  background: #ECECEC;
  -webkit-transition: all 0.1s ease-in-out;
  -moz-transition: all 0.1s ease-in-out;
  transition: all 0.1s ease-in-out;
}

.bordered th:first-child {
  -moz-border-radius: 6px 0 0 0;
  -webkit-border-radius: 6px 0 0 0;
  border-radius: 6px 0 0 0;
}

.bordered th:last-child {
  -moz-border-radius: 0 6px 0 0;
  -webkit-border-radius: 0 6px 0 0;
  border-radius: 0 6px 0 0;
}

.bordered th:only-child {
  -moz-border-radius: 6px 6px 0 0;
  -webkit-border-radius: 6px 6px 0 0;
  border-radius: 6px 6px 0 0;
}

.bordered td:first-child {
  -moz-border-radius: 0 0 0 6px;
  -webkit-border-radius: 0 0 0 6px;
  border-radius: 0 0 0 6px;
}

td {
  vertical-align: middle;
}

thead th {
  font-size: 14px;
}

/* Scroll */
.overflow-x-hidden {
  overflow-x: hidden !important;
}

.overflow-y-scroll {
  overflow-y: scroll !important;
}

/* Scrollbar */
::-webkit-scrollbar {
  width: 8px;
}

::-webkit-scrollbar-thumb {
  background-color: rgba(0, 0, 0, 0.1);
  border-radius: 3px;
}

::-webkit-scrollbar-track {
  background: transparent;
}

/*Validation Form*/
.v-feedback {
  margin-top: .25rem;
  font-size: .875em;
  font-weight: 500;
}

.v-feedback-input {
  border-color: #D30607 !important;
}

/*TODO: Зачем??*/
:root {
  /* Color */
  --vc-clr-primary: pink;
}

</style>
