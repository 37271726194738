import { createRouter, createWebHistory } from 'vue-router'
import StoriesView from '../views/StoriesView'
// eslint-disable-next-line
import { isUserLoggedIn } from '../auth/utils'

const routes = [
  {
    path: '/',
    redirect: '/stories',
  },
  {
    path: '/stories',
    name: 'stories',
    component: StoriesView,
  },

  {
    path: '/menu',
    name: 'menu',
    component: () => import('@/views/MenuView'),
  },
  {
    path: '/loyalty',
    name: 'loyalty',
    component: () => import('@/views/LoyaltyView'),
  },
  {
    path: '/basket',
    name: 'basket',
    component: () => import('@/views/BasketView'),
  },
  {
    path: '/contacts',
    name: 'contacts',
    component: () => import('@/views/ContactsView'),
  },
  {
    path: '/stories/add',
    name: 'story_new',
    component: () => import('@/views/StoryView'),
  },
  {
    path: '/stories/edit/:id',
    name: 'story_edit',
    component: () => import('@/views/StoryView'),
  },
  {
    path: '/preview',
    name: 'preview',
    component: () => import('@/components/preview/PreviewView'),
  },
  {
    path: '/login',
    name: 'login',
    component: () => import('@/views/auth/LoginView'),
    meta: {
      resource: 'Auth',
      redirectIfLoggedIn: true,
      hideNavbar: true,
    },
  },
  {
    path: '/404',
    name: 'PageNotExist',
    component: () => import('@/views/error/Error404'),
  },
  {
    path: '/:catchAll(.*)', // Unrecognized path automatically matches 404
    redirect: '/404',
  },
]

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes,
})

// eslint-disable-next-line
router.beforeEach(async (to, from) => {
  const isAuthenticated = isUserLoggedIn()
  if (!isAuthenticated && to.name !== 'login') {
    return { name: 'login' }
  }
})

export default router
